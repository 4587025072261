import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';
import styles from './order.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ оплачен!"
        description="Подтверждение на почте. Мы вам сообщим, когда заказ будет готов и передан в службу
  доставки."
      />
      <YandexTableau />
    </>
  );
}

function PaymentPendingPage() {
  const [type, setType] = useState(null);
  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const result = params.get('type');
    if (result) {
      setType(result);
    }
  }, []);

  return (
    <Layout>
      <OneColumn>
        <div className={styles.block}>
          <h1 className={cn({ [styles.header]: true, [styles.success]: true })}>
            Ура! Заказ оплачен
          </h1>
          <p className={styles.description}>
            {type === 'design'
              ? 'Подтверждение на почте. Мы вам сообщим, когда дизайн будет готов.'
              : 'Подтверждение на почте. Мы вам сообщим, когда заказ будет готов и передан в службу доставки.'}
          </p>
        </div>
      </OneColumn>
    </Layout>
  );
}

export default PaymentPendingPage;
